import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';

import image from './img/4of5.png'
import css from './SectionPurchases.module.css';

const SectionPurchases = props => {
  return (
    <div className={css.root}>
      <H2 className={css.info}>
        <FormattedMessage
          id="SectionPurchases.info"
          values={{ lineBreak: <br /> }}
        />
      </H2>

      <div className={css.imageContainer}>
        <img className={css.image} src={image} />
      </div>
    </div>
  );
};

export default SectionPurchases;
