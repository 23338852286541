import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  return (
    <div className={css.root}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionHowItWorks.title" />
        <p className={css.info}>
          <FormattedMessage id="SectionHowItWorks.info" />
        </p>
      </H2>
      <div className={css.gridContaier}>
        <div className={css.card}>
          <H2 className={css.number}>1.</H2>
          <H2 className={css.cardTitle}>
            <FormattedMessage
              id="SectionHowItWorks.card1Title"
              values={{ lineBreak: <br /> }}
            />
          </H2>
          <p className={css.cardContent}>
            <FormattedMessage id="SectionHowItWorks.card1Info" />
          </p>
        </div>
        <div className={css.card}>
          <H2 className={css.number}>2.</H2>
          <H2 className={css.cardTitle}>
            <FormattedMessage
              id="SectionHowItWorks.card2Title"
              values={{ lineBreak: <br /> }}
            />
          </H2>
          <p className={css.cardContent}>
            <FormattedMessage id="SectionHowItWorks.card2Info" />
          </p>
        </div>
        <div className={css.card}>
          <H2 className={css.number}>3.</H2>
          <H2 className={css.cardTitle}>
            <FormattedMessage id="SectionHowItWorks.card3Title" />
          </H2>
          <p className={css.cardContent}>
            <FormattedMessage id="SectionHowItWorks.card3Info" />
          </p>
        </div>
      </div>
      <NamedLink
        className={css.ctaButton}
        name="SearchPage"
      >
        <FormattedMessage id="SectionHowItWorks.ctaButton" />
      </NamedLink>
    </div>
  );
};

export default SectionHowItWorks;
