import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';

import shopHero from './img/shopHero.jpg'
import css from './SectionHero.module.css';

const SectionHero = props => {
  return (
    <div className={css.root}>
      <div className={css.backgroundImageWrapper}>
        <img
          className={css.backgroundImage}
          src={shopHero}
        />
      </div>
      <div className={css.sectionContent}>
        <div className={css.infoContainer}>
          <H2 className={css.title}>
            <FormattedMessage
              id="SectionHero.title"
              values={{ lineBreak: <br /> }}
            />
          </H2>
          <p className={css.description}>
            <FormattedMessage id="SectionHero.description" />
          </p>

          <NamedLink
            className={css.ctaButton}
            name="SearchPage"
          >
            <FormattedMessage id="SectionHero.ctaButton" />
          </NamedLink>

        </div>
      </div>
    </div>
  );
};

export default SectionHero;
