import React from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';

import ClothingRack from './img/Clothing-rack.png';
import Countertop from './img/Countertop.png';
import GlassDisplay from './img/Glass-display.png';
import Mannequin from './img/Mannequin.png';
import OtherDisplay from './img/Other-display.png';
import ShelfDisplay from './img/Shelf-display.png';
import TableDisplay from './img/Table-display.png';
import WindowDisplay from './img/Window-display.png';
import css from './SectionDisplays.module.css';

const SectionDisplays = props => {

  const intl = useIntl();
  const displayTypes = [
    {
      image: ClothingRack,
      label: intl.formatMessage({ id: 'SectionDisplays.ClothingRackLabel' })
    },
    {
      image: WindowDisplay,
      label: intl.formatMessage({ id: 'SectionDisplays.WindowDisplayLabel' })
    },
    {
      image: TableDisplay,
      label: intl.formatMessage({ id: 'SectionDisplays.TableDisplayLabel' })
    },
    {
      image: GlassDisplay,
      label: intl.formatMessage({ id: 'SectionDisplays.GlassDisplayLabel' })
    },
    {
      image: Mannequin,
      label: intl.formatMessage({ id: 'SectionDisplays.MannequinLabel' })
    },
    {
      image: ShelfDisplay,
      label: intl.formatMessage({ id: 'SectionDisplays.ShelfDisplayLabel' })
    },
    {
      image: Countertop,
      label: intl.formatMessage({ id: 'SectionDisplays.CountertopLabel' })
    },
    {
      image: OtherDisplay,
      label: intl.formatMessage({ id: 'SectionDisplays.OtherDisplayLabel' })
    }
  ];

  return (
    <div className={css.root}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionDisplays.title" />
      </H2>
      <div className={css.displayContainer} style={{ gridTemplateColumns: `repeat(${displayTypes.length}, 1fr)` }}>
        {displayTypes.map((display, index) => {
          return (
            <div className={css.displayCard} key={index}>
              <img className={css.image} src={display.image} alt={display.label} />
              <span className={css.label}>{display.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionDisplays;
