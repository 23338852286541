import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';
import Arty from './img/Arty.png';
import Green from './img/Green.png';
import Kind from './img/Kind.png';

import css from './SectionCrafting.module.css';

const SectionCrafting = props => {
  return (
    <div className={css.root}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionCrafting.title" />
      </H2>
      <p className={css.subtitle}>
        <FormattedMessage
          id="SectionCrafting.subtitle1"
        />
      </p>
      <p className={css.subtitle}>
        <FormattedMessage
          id="SectionCrafting.subtitle2"
          values={{ lineBreak: <br /> }}
        />
      </p>
      <p className={css.info}>
        <FormattedMessage
          id="SectionCrafting.info"
        />
      </p>

      <div className={css.grid}>
        <div className={css.card}>
          <img className={css.image} src={Green} />
          <span className={css.cardTitle}>
            <FormattedMessage
              id="SectionCrafting.green"
            />
          </span>
          <FormattedMessage
            id="SectionCrafting.greenAttributes"
            values={{ lineBreak: <br /> }}
          />
        </div>
        <div className={css.card}>
          <img className={css.image} src={Arty} />
          <span className={css.cardTitle}>
            <FormattedMessage
              id="SectionCrafting.arty"
            />
          </span>
          <FormattedMessage
            id="SectionCrafting.artyAttributes"
            values={{ lineBreak: <br /> }}
          />
        </div>
        <div className={css.card}>
          <img className={css.image} src={Kind} />
          <span className={css.cardTitle}>
            <FormattedMessage
              id="SectionCrafting.kind"
            />
          </span>
          <FormattedMessage
            id="SectionCrafting.kindAttributes"
            values={{ lineBreak: <br /> }}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionCrafting;
