import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import SectionHero from './sections/SectionHero/SectionHero';
import SectionPurchases from './sections/SectionPurchases/SectionPurchases';
import SectionHowItWorks from './sections/SectionHowItWorks/SectionHowItWorks';
import SectionDisplays from './sections/SectionDisplays/SectionDisplays';
import SectionBrand from './sections/SectionBrand/SectionBrand';
import SectionCrafting from './sections/SectionCrafting/SectionCrafting';
import SectionRegister from './sections/SectionRegister/SectionRegister';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

export const LandingPageComponent = props => {

  return (
    <Page>
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <SectionHero />
        <SectionPurchases />
        <SectionHowItWorks />
        <SectionDisplays />
        <SectionBrand />
        <SectionRegister />
        <SectionCrafting />
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
